<template>
  <div>
    <base-page
      title="Products"
      :breadcrumbs="breadcrumbs"
      call-to-action-text="Add product"
      call-to-action-permission="retail.products.store"
      @call-to-action="dialog = true"
    >
      <template #content>
        <product-list
          @edit="edit"
        />
      </template> 
    </base-page>

    <product-form 
      v-if="dialog"
      :dialog="dialog"
      :product="product"
      @close="close()"
    />
  </div>
</template>

<script>
export default {
  components: {
    'product-list': () => import('@/components/retail/ProductList.vue'),
    'product-form': () => import('@/components/retail/ProductForm.vue'),
  },

  data () {
    return {
      dialog: false,
      product: null,
      breadcrumbs: [
        { text: 'Products', disabled: true, },
      ],
    }
  },

  methods: {
    close () {
      this.dialog = false
      this.product = null
    },

    edit (product) {
      this.product = product
      this.dialog = true
    }
  }
}
</script>